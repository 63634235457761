import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCalendarusers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/calendar-users', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchCalendaruser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/calendar-users/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateCalendaruser(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/calendar-users/${id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addCalendaruser(ctx, calendaruserData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/calendar-users', calendaruserData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteCalendaruser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/calendar-users/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
