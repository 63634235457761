import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCalendarusersList() {
  // Use toast
  const toast = useToast()

  const refCalendarusersListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'nome', sortable: true },
    { key: 'link', sortable: true },
    { key: 'utente', sortable: true },
    { key: 'azioni' },
  ]
  const fields = {
    id: 'id',
    nome: 'name',
    link: 'url',
    utente: 'user_id',
  }

  const perPage = ref(10)
  const totalCalendarusers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const userFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refCalendarusersListTable.value ? refCalendarusersListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCalendarusers.value,
    }
  })

  const refetchData = () => {
    refCalendarusersListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, userFilter], () => {
    refetchData()
  })

  const fetchCalendarusers = (ctx, callback) => {
    store
      .dispatch('app-calendarusers/fetchCalendarusers', {
        text_search: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value - 1,
        sort_by: fields[sortBy.value],
        sort_direction: isSortDirDesc.value === true ? 'DESC' : 'ASC',
        user_id: userFilter.value,
      })
      .then(response => {
        const calendarusers = response.data[0]
        const total = response.data[1]

        callback(calendarusers)
        totalCalendarusers.value = total
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'user') return 'info'
    if (role === 'hr') return 'warning'
    if (role === 'reception_hub') return 'warning'
    if (role === 'reception_colle') return 'warning'
    if (role === 'accountant') return 'warning'
    if (role === 'admin') return 'danger'
    return 'secondary'
  }

  return {
    fetchCalendarusers,
    tableColumns,
    perPage,
    currentPage,
    totalCalendarusers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCalendarusersListTable,

    refetchData,

    resolveUserRoleVariant,

    // Filters
    userFilter,
  }
}
