<template>
  <div>
    <b-card class="text-center">
      <h1><feather-icon
        class="mr-2"
        icon="LinkIcon"
        size="25"
      />Calendari Utente</h1>
    </b-card>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <calendarusers-list-filters
        :user-filter.sync="userFilter"
        :user-options="users"
      />
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostra</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>risultati</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Cerca..." /> -->
              <b-button
                variant="primary"
                class="text-nowrap"
                @click="$bvModal.show('create-modal')"
              >Nuovo Calendario Utente</b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refCalendarusersListTable"
        class="position-relative"
        :items="fetchCalendarusers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nessun Calendario Utente corrispondente trovato"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: ID -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">#{{ data.item.id }}</span>
          </div>
        </template>

        <!-- Column: Name -->
        <template #cell(nome)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.name }}</span>
          </div>
        </template>

        <!-- Column: Link -->
        <template #cell(link)="data">
          <b-link
            :href="data.item.url"
            target="_blank"
          >
            <feather-icon
              class="mr-1 text-primary"
              icon="LinkIcon"
            />
            <span
              class="align-text-top"
            >{{ data.item.url.substring(0,30) }}{{ data.item.url.length > 30 ? '...' : '' }}</span>
          </b-link>
        </template>

        <template #cell(utente)="data">
          <div class="text-nowrap">
            <div class="d-flex align-items-center">
              <b-avatar
                size="25"
                :src="data.item.user.avatar_file ? mediaUrl + data.item.user.avatar_file : ''"
                :text="avatarText(data.item.user.display_name)"
                :variant="`light-${resolveUserRoleVariant(data.item.user.role_code)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.user.id } }"
                class="mr-1"
              />
              <b-link :to="{ name: 'apps-users-view', params: { id: data.item.user.id } }">
                #{{ data.item.user.anagrafico_id }} - {{ data.item.user.display_name }}
              </b-link>
            </div>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(azioni)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="
                selectedUser = {label: '#' + data.item.user.anagrafico_id + ' - ' + data.item.user.display_name, value: data.item.user.id}
                updatedCalendaruser = Object.assign({},data.item)
                $bvModal.show('update-modal')
              "
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifica</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="
                $bvModal.show('delete-modal')
                selectedCalendaruser = data.item.id
              "
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Cancella</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando da {{ dataMeta.from }} a {{ dataMeta.to }} di {{ dataMeta.of }} risultati</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCalendarusers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-modal
        id="create-modal"
        title="Nuovo Calendario Utente"
        size="lg"
        footer-class="d-flex justify-content-end"
        centered
      >
        <template #modal-header="{ close }">
          <h5 class="mb-0">
            Nuovo Calendario Utente
          </h5>
          <feather-icon
            icon="XIcon"
            size="18"
            @click="close()"
          />
        </template>
        <template>
          <b-form>
            <b-row class="mt-2 mb-2">
              <b-col
                cols="12"
                lg="6"
                class="mb-1"
              >
                <b-form-group
                  label="Nome Calendario Utente"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="newCalendaruser.name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="6"
                class="mb-1"
              >
                <b-form-group
                  label="Link Calendario Utente"
                  label-for="url"
                >
                  <b-form-input
                    id="url"
                    v-model="newCalendaruser.url"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                lg="6"
                class="mb-1"
              >
                <b-form-group
                  label="Seleziona Utente"
                  label-for="user_id"
                >
                  <v-select
                    id="user_id"
                    :filterable="false"
                    :options="usersPaginated"
                    @open="onOpen"
                    @close="onClose"
                    @search="(query) => (search = query)"
                    @input="selectUser($event)"
                  >
                    <template #list-footer>
                      <li
                        v-show="hasNextPage"
                        ref="load"
                        class="loader"
                      >
                        Carica più Utenti...
                      </li>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </template>
        <template #modal-footer="{ cancel }">
          <b-button
            variant="outline-danger"
            @click="cancel()"
          >
            Annulla
          </b-button>
          <b-button
            variant="success"
            @click="createCalendaruser()"
          >
            Conferma
          </b-button>
        </template>
      </b-modal>
      <b-modal
        id="update-modal"
        title="Modifica Calendario Utente"
        size="lg"
        footer-class="d-flex justify-content-end"
        centered
      >
        <template #modal-header="{ close }">
          <h5 class="mb-0">
            Modifica Calendario Utente
          </h5>
          <feather-icon
            icon="XIcon"
            size="18"
            @click="close()"
          />
        </template>
        <template>
          <b-form>
            <b-row class="mt-2 mb-2">
              <b-col
                v-if="updatedCalendaruser && updatedCalendaruser.name"
                cols="12"
                lg="6"
                class="mb-1"
              >
                <b-form-group
                  label="Nome Calendario Utente"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="updatedCalendaruser.name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="updatedCalendaruser && updatedCalendaruser.url"
                cols="12"
                lg="6"
                class="mb-1"
              >
                <b-form-group
                  label="Link Calendario Utente"
                  label-for="url"
                >
                  <b-form-input
                    id="url"
                    v-model="updatedCalendaruser.url"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="selectedUser"
                cols="12"
                lg="6"
                class="mb-1"
              >
                <b-form-group
                  label="Seleziona Utente"
                  label-for="user_id"
                >
                  <v-select
                    id="user_id"
                    v-model="selectedUser"
                    :filterable="false"
                    :options="usersPaginated"
                    @open="onOpen"
                    @close="onClose"
                    @search="(query) => (search = query)"
                    @input="selectUpdatedUser($event)"
                  >
                    <template #list-footer>
                      <li
                        v-show="hasNextPage"
                        ref="load"
                        class="loader"
                      >
                        Carica più Utenti...
                      </li>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </template>
        <template #modal-footer="{ cancel }">
          <b-button
            variant="outline-danger"
            @click="cancel()"
          >
            Annulla
          </b-button>
          <b-button
            variant="success"
            @click="updateCalendaruser()"
          >
            Conferma
          </b-button>
        </template>
      </b-modal>
      <b-modal
        id="delete-modal"
        title="Cancella Calendario Utente"
        footer-class="d-flex justify-content-end"
        body-class="p-0"
        size="sm"
        centered
      >
        <template #modal-header="{ close }">
          <h5 class="mb-0">
            Cancella Calendario Utente
          </h5>
          <feather-icon
            icon="XIcon"
            size="18"
            @click="close()"
          />
        </template>
        <p class="m-2">
          Sei sicuro di voler cancellare definitivamente questo Calendario Utente e i suoi dati correlati?
        </p>
        <template #modal-footer="{ ok, cancel }">
          <b-button
            size="sm"
            variant="danger"
            @click="cancel()"
          >
            Annulla
          </b-button>
          <b-button
            size="sm"
            variant="success"
            @click="
              ok()
              deleteCalendaruser(selectedCalendaruser)
            "
          >
            OK
          </b-button>
        </template>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BButton,
  BMedia,
  BLink,
  BImg,
  BModal,
  BAvatar,
  BForm,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import userStoreModule from '@/views/apps/user/userStoreModule'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useCalendarusersList from './useCalendarusersList'
import calendarusersStoreModule from '../calendarusersStoreModule'
import CalendarusersListFilters from './CalendarusersListFilters.vue'

export default {
  components: {
    CalendarusersListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BMedia,
    BLink,
    BImg,
    BModal,
    BAvatar,
    BForm,
    BFormGroup,

    vSelect,
  },
  setup() {
    const toast = useToast()

    const CALENDARUSERS_APP_STORE_MODULE_NAME = 'app-calendarusers'
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(CALENDARUSERS_APP_STORE_MODULE_NAME)) {
      store.registerModule(CALENDARUSERS_APP_STORE_MODULE_NAME, calendarusersStoreModule)
    }
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDARUSERS_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDARUSERS_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const mediaUrl = process.env.VUE_APP_MEDIA_URL

    const users = ref(null)
    const _users = []
    store
      .dispatch('app-user/fetchUsers', { per_page: 1000, include_me: true })
      .then(response => {
        response.data[0].forEach((user, i) => {
          const _user = {
            label: `#${user.anagrafico_id} - ${user.display_name}`,
            value: user.id,
          }
          _users.push(_user)
        })
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
    users.value = _users

    const {
      fetchCalendarusers,
      tableColumns,
      perPage,
      currentPage,
      totalCalendarusers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCalendarusersListTable,
      refetchData,

      resolveUserRoleVariant,

      userFilter,
    } = useCalendarusersList()

    return {
      fetchCalendarusers,
      tableColumns,
      perPage,
      currentPage,
      totalCalendarusers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCalendarusersListTable,
      refetchData,
      resolveUserRoleVariant,

      // Filter
      userFilter,
      users,
      avatarText,
      mainProps: {
        width: 100,
        height: 50,
      },
      blankProps: {
        blank: true,
        blankColor: '#777',
        width: 100,
        height: 50,
      },
      selectedCalendaruser: null,
      selectedUser: null,
      updatedCalendaruser: null,
      mediaUrl,
      observer: null,
      limit: 10,
      search: '',
      loading: false,
      newCalendaruser: {
        name: null,
        url: null,
        user_id: null,
      },
    }
  },
  computed: {
    usersFiltered() {
      return this.users.filter(data => JSON.stringify(data).toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    },
    usersPaginated() {
      return this.usersFiltered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.usersPaginated.length < this.usersFiltered.length
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    selectUser(e) {
      this.newCalendaruser.user_id = e.value
    },
    selectUpdatedUser(e) {
      this.updatedCalendaruser.user_id = e.value
    },
    createCalendaruser() {
      this.loading = true
      store
        .dispatch('app-calendarusers/addCalendaruser', this.newCalendaruser)
        .then(result => {
          this.newCalendaruser = {
            name: null,
            url: null,
            user_id: null,
          }
          this.refetchData()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Calendario Utente #${result.data.id} aggiunto con successo`,
              icon: 'LinkIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
      this.loading = false
      this.$bvModal.hide('create-modal')
    },
    updateCalendaruser() {
      this.loading = true
      const { id } = this.updatedCalendaruser
      const formData = {
        name: this.updatedCalendaruser.name,
        url: this.updatedCalendaruser.url,
        user_id: this.updatedCalendaruser.user_id,
      }
      store
        .dispatch('app-calendarusers/updateCalendaruser', { id, formData })
        .then(() => {
          this.updatedCalendaruser = null
          this.refetchData()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Calendario Utente #${id} modificato con successo`,
              icon: 'LinkIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
      this.loading = false
      this.$bvModal.hide('update-modal')
    },
    deleteCalendaruser(id) {
      store
        .dispatch('app-calendarusers/deleteCalendaruser', { id })
        .then(() => {
          this.selectedCalendaruser = null
          this.refetchData()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Calendario Utente #${id} eliminato con successo`,
              icon: 'LinkIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.media-body {
  align-self: center;
}
.meetingroom_list_img {
  object-fit: cover;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
